import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  getContentSuccess(title, text) {
    const content = {
      component: ToastificationContent,
      props: {
        icon: 'BellIcon',
        variant: 'success',
        title,
        text,
      },
    }

    return content
  },

  getContentError(title, text) {
    const content = {
      component: ToastificationContent,
      props: {
        icon: 'BellIcon',
        variant: 'danger',
        title,
        text,
      },
    }

    return content
  },

  getContentInfo(title, text) {
    const content = {
      component: ToastificationContent,
      props: {
        icon: 'InfoIcon',
        variant: 'info',
        title,
        text,
      },
    }

    return content
  },
}
